/* eslint-disable no-console,no-undef,camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
  FeaturedEventsContainer,
  TitleContent,
} from "../FeaturedEvents/styles";
import NewUpComingFeatureChalleng from "./NewUpComingFeatureChalleng";
import {
  getFeaturedChallenges,
  featuredChallengesAPI,
  joinOrLeaveChallenge,
  toggleDummyChallenges,
} from "../../redux/actions";
import Waiting from "../Waiting";
import {
  ChallengeDashboardContainer,
  ChallengeHeader,
  ChallengeHeadermain,
  SeeAllButton,
} from "../ChallengeDashboardV2/styles";
import { withTranslation } from "react-i18next";

class FeaturedChallenges extends React.Component {
  constructor() {
    super();
    this.state = {
      upcomingShoeMore: false,
    };
  }

  componentDidMount() {
    const { featuredChallengesAPI, featureChallengesData } = this.props;
    if (!featureChallengesData) featuredChallengesAPI();
  }

  upComingCardMore = () => {
    this.setState((prev) => ({ upcomingShoeMore: !prev.upcomingShoeMore }));
  };

  render() {
    const {
      isSocialFeedsPage,
      joinOrLeaveChallenge,
      history,
      featuredWith,
      toggleDummyChallenges,
      isDummyChallenge,
      upcomingChallenge,
      t,
    } = this.props;
    const { upcomingShoeMore } = this.state;

    if (isNull(upcomingChallenge) || isUndefined(upcomingChallenge)) {
      return <Waiting />;
    }
    const slicedUserData = upcomingChallenge?.slice(0, 2);
    if (upcomingShoeMore === true) {
      return (
        <ChallengeDashboardContainer>
          <ChallengeHeadermain>
            <div>
              <ChallengeHeader color={1}>
                {upcomingChallenge && upcomingChallenge.length >= 1
                  ? t("My Upcoming Challenges")
                  : ""}
              </ChallengeHeader>
            </div>
            <div>
              {upcomingChallenge && upcomingChallenge.length > 2 ? (
                <SeeAllButton
                  onClick={() => this.upComingCardMore()}
                  color={"color"}
                >
                  {this.state.upcomingShoeMore
                    ? t("See Less <")
                    : t("See All >")}
                </SeeAllButton>
              ) : (
                ""
              )}
            </div>
          </ChallengeHeadermain>
          {upcomingChallenge.length ? (
            <FeaturedEventsContainer bgColor={1}>
              <TitleContent border={1} feature={1} isSocialFeedsPage />
              {upcomingChallenge.map((challenge, index) => (
                <NewUpComingFeatureChalleng
                  key={index}
                  challenge={challenge}
                  isSocialFeedsPage={isSocialFeedsPage}
                  joinOrLeaveChallenge={joinOrLeaveChallenge}
                  history={history}
                  featuredWith={featuredWith}
                  toggleDummyChallenges={toggleDummyChallenges}
                  isDummyChallenge={isDummyChallenge}
                />
              ))}
            </FeaturedEventsContainer>
          ) : null}
        </ChallengeDashboardContainer>
      );
    } else {
      return (
        <ChallengeDashboardContainer>
          <ChallengeHeadermain>
            <div>
              <ChallengeHeader color={1}>
                {upcomingChallenge && upcomingChallenge.length >= 1
                  ? t("My Upcoming Challenges")
                  : ""}
              </ChallengeHeader>
            </div>
            <div>
              {upcomingChallenge && upcomingChallenge.length > 2 ? (
                <SeeAllButton
                  onClick={() => this.upComingCardMore()}
                  color={"color"}
                >
                  {this.state.upcomingShoeMore
                    ? t("See Less <")
                    : t("See All >")}
                </SeeAllButton>
              ) : (
                ""
              )}
            </div>
          </ChallengeHeadermain>
          {slicedUserData?.length ? (
            <FeaturedEventsContainer bgColor={1}>
              <TitleContent border={1} feature={1} isSocialFeedsPage />
              {slicedUserData?.map((challenge, index) => (
                <NewUpComingFeatureChalleng
                  key={index}
                  challenge={challenge}
                  isSocialFeedsPage={isSocialFeedsPage}
                  joinOrLeaveChallenge={joinOrLeaveChallenge}
                  history={history}
                  featuredWith={featuredWith}
                  toggleDummyChallenges={toggleDummyChallenges}
                  isDummyChallenge={isDummyChallenge}
                />
              ))}
            </FeaturedEventsContainer>
          ) : null}
        </ChallengeDashboardContainer>
      );
    }
  }
}

FeaturedChallenges.propTypes = {
  isSocialFeedsPage: PropTypes.bool,
  fetchFeaturedChallenge: PropTypes.func.isRequired,
  featuredChallengesAPI: PropTypes.func.isRequired,
  featureChallengesData: PropTypes.array,
  joinOrLeaveChallenge: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  featuredWith: PropTypes.number,
  toggleDummyChallenges: PropTypes.func,
  isDummyChallenge: PropTypes.bool,
  t: PropTypes.func,
  upcomingChallenge: PropTypes.array,
};

const mapStateToProps = (state) => ({
  featureChallengesData: state.challenges.featureChallenges,
  challengeData: state.challenges.challengeData,
  isDummyChallenge: state.challenges.isDummyChallenge,
  upcomingChallenge: state.challenges.upcomingChallenge,
});

const mapDispatchToProps = (dispatch) => ({
  featuredChallengesAPI: () => dispatch(featuredChallengesAPI()),
  fetchFeaturedChallenge: () => dispatch(getFeaturedChallenges()),
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  toggleDummyChallenges: (bool) => dispatch(toggleDummyChallenges(bool)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(FeaturedChallenges));
