/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { MainContainerNew } from './newFeatureStyle';
import ImageThumbnail from '../ImageThumbnail';
import {
  getStatus,
  convertDateInTimezone,
  getChallengeTimeToShow,
  convertMonthFromDate
} from '../../utils/methods';
import { ImageUrl } from '../../utils/constants';
import { ImageContainerNew, TextContainerNew, SubContainerNew } from './newFeatureStyle';
import { withTranslation } from 'react-i18next';

class SingleFeaturedChallenge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge)
    }
  }

  componentDidMount() {
    const { challenge } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        time: getChallengeTimeToShow(challenge)
      })
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };
  fallBack = (e) => {
    e.target.src = '/public/images/fallback.png'
  };
  challengeNavigation = (challengeId, isDummy) =>{
    const {history, toggleDummyChallenges} = this.props;
    if(isDummy) {
      toggleDummyChallenges(true);
    }else{
      history.push(`/challenges/${challengeId}`);
    }
  }
  componentDidUpdate(){
    const{isDummyChallenge, history} = this.props;
    if(isDummyChallenge){
      history.push(`/challenges/${this.props.challenge.id}${isDummyChallenge ? '?ACME=true' : ''}`);
    }
  }
  render() {
    const { time} = this.state;
    const { challenge ,padding, t } = this.props;
    let challengeId = challenge.id;
    const DaysLeft = time.map((step) => step);
    return (
      <div>
        <MainContainerNew paddingValue={padding === 1 ? '25px 25px 0px 0px' : '0px 0 25px 0'}>
          <div />
          <SubContainerNew onClick={() => this.challengeNavigation(challengeId, challenge.is_dummy_challenge)}>
            <div>
              <ImageContainerNew>
                <ImageThumbnail src={`${ImageUrl}/${challenge['image']}`} alt={"slide"}/>
              </ImageContainerNew>
              <TextContainerNew>
                <p>{challenge.title}</p>
              </TextContainerNew>
            </div>
            <div>
              <div>
                <div>{t("Start Date")} : {convertMonthFromDate(moment(convertDateInTimezone(challenge && challenge.start_date)._d).format('MMM DD, YYYY'), this.props)}</div>
              </div>
              <div>
                <div>{time.map((step, index) => step + (index === (time.length - 1) ? '' : ', '))}
                  {(DaysLeft == 'Starts Today' || DaysLeft == 'Last Day') ? null :(convertDateInTimezone(challenge.start_date)._d <= momentTZ()._d ? t(' remaining') : t(' left to start'))}
                </div>
              </div>
            </div>
          </SubContainerNew>
        </MainContainerNew>
      </div>
    )
  }
}

SingleFeaturedChallenge.propTypes = {
  joinOrLeaveChallenge: PropTypes.func.isRequired,
  challenge: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  featuredWith: PropTypes.number,
  toggleDummyChallenges: PropTypes.func,
  isDummyChallenge: PropTypes.bool,
  padding: PropTypes.string,
  t: PropTypes.func
};

export default (withTranslation()(SingleFeaturedChallenge));