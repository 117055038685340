import styled from 'styled-components';

import { EventContainer } from '../FeaturedEvents/styles';

const ChallengeContainer = styled(EventContainer)`
  padding: 0px;
  cursor: pointer;
  border-bottom: none;
`;

const SvgContainer = styled.div`
  width: 100%;
  float: left;
  border-bottom: ${(props) => props.isSocialFeedsPage && '1px solid #e2e2e4'};
  display: flex;
  align-items: center;
  
  > span {
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(153,153,153);
  }
  
  > svg {
    width: 15px;
    margin-right: 3px;
  }
`;

const DateIcon = styled.img`
  height: 13px;
  width: 13px;
  margin-right: 5px;
`;

export { SvgContainer, DateIcon, ChallengeContainer };