import styled from 'styled-components';

const RegularFont = 'Rubik';
const MediumFont = 'Rubik-Medium';

const MainContainer = styled.div`
float: left;
width: 100%;
height: 100%;
width: 100%;
padding-top: 25px;
padding-right: 25px;
padding: ${({ paddingValue }) => paddingValue ? paddingValue : '15px 25px 0px 0px'};
min-height: auto;
  > div: first-child {
    text-transform: capitalize;
    font-size: 18px;
    color: #0D4270;
    font-family: ${MediumFont};
	}
  @media(max-width: 1200px) {
		width: 100%;
		margin-right: 0px;
  }
  @media (max-width: 766px) {
    width: 100%;
  }

`;
const SubContainer = styled.div`
width: 100%;
height: 100px;
cursor: pointer;
background-color: #FFF;
margin-top: 15px;
border-radius: 6px;
box-shadow: 0 2px 2px rgb(0 0 0 / 19%), 0 4px 6px rgb(0 0 0 / 7%);

> div: first-child {
  padding: 5px;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background ? 'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)' : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding:0px;
  border-radius: 6px 0px 0px 6px;
}

> div: last-child {
  width: 100%;
  height: 100%;
  padding: 7px;
  display: flex;
  justify-content: space-between;
  padding:0px;
  border-radius: 6px;
}
`;

const ImageContainer = styled.div`
width: 15%;
min-width: 125px;
height: 100px;
float: left;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 5px 0px 0px 5px;
  }
`;
const TextContainer = styled.div`
width: 80%;
height: 100%;
padding: 15px;
float: right;
display: flex;
justify-content: space-evenly;
flex-flow: column;

  > span: first-child {
    text-transform: uppercase;
    font-size: 14px;
    color: #9ECDFF;
    font-family: ${RegularFont};
    color: ${({ color }) => color ? '#fff' : '#9ECDFF'};
  }
  > p {
    text-transform: uppercase;
    font-size: 15px;
    color: #FFF;
    font-family: ${MediumFont};
  }
  > div {
    width: 100%;
    display: flex;

    > span {
    width: 20%;
    font-size: 10px;
    color: #F6B479;
    font-family: ${RegularFont};
    padding-left: 5px;
    margin-top: -3px;
    }
  }
`;
const ProgressBar = styled.div`
  width: 80%;
  height: 33%;
  height: 8px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.17);
  overflow: hidden;
  .progrss {
    height: 8px;
    border-radius: 9px;
    background-color: #F6B479;
    width: ${({width}) => width && `${width}%`};
  }
`;
const DayToStart = styled.div`
font-size: 10px;
color: #0D4270;
font-family: ${RegularFont}
padding-top: 7px;
`;
const DateContainer = styled.div`
font-size: 12px;
color: #0D4270;
font-family: ${MediumFont}
padding-top: 7px;
`;
const IconContainer = styled.div`
display: flex;
margin-left: 0px;
padding-left: 5px;

  > span {
    margin-left: -12px;

    > img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 2px solid rgb(255, 255, 255);
    }
  }

  .circle {
    font-family: ${MediumFont}
    font-size: 11px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #fff;
    text-align: center;
    background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  }
`;
const IconRight = styled.div`
width: 5%;
display: flex;
padding-top: 45px;
>img{

  margin:0px;
  width:7px;
  height:16px;
}
`;

const ImageContainerNew = styled.div`
width: 15%;
min-width: 167px;
//height: 104px;
float: left;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 5px 0px 0px 0px;
  }
`;

const SubContainerNew = styled.div`
width: 100%;
// height: ${({ height }) => height ? height : '100px'};
cursor: pointer;
background-color: #FFF;
//margin-top: 15px;
border-radius: 6px;
// box-shadow: 0 2px 2px rgb(0 0 0 / 19%), 0 4px 6px rgb(0 0 0 / 7%);

> div: first-child {
  padding: 5px;
  width: 100%;
  height: ${({ height }) => height ? height : '100px'};
  background: ${({ background }) => background ? 'linear-gradient(90deg, rgb(0, 177, 188) -1.11%, rgb(117, 217, 163) 97.46%)' : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding:0px;
  border-radius: 6px 0px 0px 6px;


  padding: 7px;
  display: flex;
  justify-content: space-between;
  padding:0px;
  border-radius: 6px 6px 0px 0px;
}

> div: last-child {
  width: 100%;
  height: 50px;
  background: white;
  display: flex;
  border-radius: 0px 0px 6px 6px;
  >div: first-child{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    padding: 16px 0px 6px 15px
    >div{
      font-family: 'Rubik-Medium';
      font-size: 14px;
      line-height: 18px;
      color:#0D4270;
    }
  }
  >div: last-child{
    width: 40%;
    display: flex;
    justify-content: flex-end;
    padding: 16px 15px 0px 0px;
    >div {
      font-family: Rubik;
      font-size: 14px;
      line-height: 18px;
      color: #0D4270;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
`;

const TextContainerNew = styled.div`
width: 90%;
height: 100%;
padding: 15px;
float: right;
display: flex;
justify-content: space-evenly;
flex-flow: column;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
  > p {
    font-family: 'Rubik-Medium';
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const MainContainerNew = styled.div`
float: left;
width: 100%;
height: 100%;
padding: 15px 25px 0px 0px;
padding: ${({ paddingValue }) => paddingValue ? paddingValue : '15px 25px 0px 0px'};
  > div: first-child {
    font-size: 18px;
    color: #0D4270;
    font-family: ${MediumFont};
    display: flex;
    justify-content: center;
    width: 100%;
    >div:nth-child(1){
      //width: 50%
      display: flex;
      justify-content: flex-start;
      margin: auto;
      margin-left:0px;


      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 21px
      color: #0D4270;
    }
    >div:nth-child(2){
      width: 50%;
      display: flex;
      justify-content: flex-end;
      margin: auto;
      margin-right:0px;

      font-family: Rubik;
      font-size: 18px;
      line-height: 24px;
      color:#FD7175;
      cursor: pointer;
    }
  }
  @media(max-width: 1200px) {
		width: 100%;
		margin-right: 0px;
  }
  @media (max-width: 766px) {
    width: 100%;
  }

`;

export {
  MainContainer, SubContainer, ImageContainer, TextContainer, ProgressBar, DayToStart, DateContainer, IconContainer, IconRight, ImageContainerNew, SubContainerNew, TextContainerNew, MainContainerNew
}